import authAction from "../redux/auth/actions";
import axios from "axios";
import { getXAPIKey } from "./utility";
import { store } from "../redux/store";

const METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch",
};

const BASEURL = process.env.REACT_APP_API_URL;

// CHECK BELOW FOR SAMPLE DATA TO BE PASSED
class Api {
  isLoggedIn = false;
  userData = {};

  constructor() {
    this.baseURL = BASEURL;
  }

  getAuthenticationInfo() {
    if (localStorage.getItem("isLoggedIn")) {
      this.isLoggedIn = true;
      this.userData = store.getState().Auth;
    }
  }

  // URL FOR API
  // REFER SAMPLE JSON AT BOTTOM FOR DATA VALUES
  get(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.GET, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  post(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.POST, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  put(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PUT, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  patch(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PATCH, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  delete(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.DELETE, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  api(method, url, data) {
    return new Promise((resolve, reject) => {
      let axiosConfig = {};
      axiosConfig.method = method;
      axiosConfig.url = this.baseURL + url;
      axiosConfig.headers = this.setHeaders(data);
      if (data) {
        if (data.params) axiosConfig.params = data.params;

        if (data.data) {
          let json = JSON.stringify(data.data);
          axiosConfig.data = json;
        }
      }
      axios(axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response !== undefined) {
            if (error.response.status === 401) {
              let data = error.response.data;
              if (data.status === 2) {
                store.dispatch(
                  authAction.pendingPayment({
                    showPage: true,
                    userId: data.data.userId,
                    hasCard: data.data.isCardAdded,
                  })
                );
              } else {
                store.dispatch(authAction.logout());
              }
            }
          }
          if (error.response && error.response.data) {
            resolve(error.response.data);
          } else {
            resolve(error);
          }

          //DEFAULT ERROR HANDLING
        });
    });
  }

  setHeaders(data) {
    let headers = {};
    headers["accept-language"] = "en";
    headers["x-api-key"] = getXAPIKey();

    if (data) {
      if (data.isMultipart) headers["Content-Type"] = "multipart/form-data";
      else headers["Content-Type"] = "application/json";

      if (data.headers)
        for (var key in data.headers)
          if (data.headers.hasOwnProperty(key))
            headers[key] = data.headers[key];
    }

    if (
      store.getState().Auth.accessToken !== "" &&
      store.getState().Auth.accessToken !== "eyJhbGciOiJIUzUxMiJ9"
    ) {
      headers["access-token"] = store.getState().Auth.accessToken;
    }
    return headers;
  }
}

export default Api;
