import React from "react";
import { faGears, faUserGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import StatisticsCard from "../uielements/StatisticsCard";
import { withRouter } from "react-router-dom";
import SettingsCount from "../Counts/SettingsCount";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  @media only screen and (max-width: 425px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const GeneralCertification = (props) => {
  return (
    <>
      <SettingsCount />
    </>
  );
};

export default withRouter(GeneralCertification);
