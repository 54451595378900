import React, { Component, Fragment, useState } from "react";

import Api from "./../../helpers/apiHandler";
import { Link } from "react-router-dom";
import Popover from "../../components/uielements/popover";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import authAction from "../../redux/auth/actions";
import { connect } from "react-redux";
import { Modal, Form, Input, message } from "antd";
import userpic from "../../image/user1.png";
import varAction from "../../redux/variables/actions";
import awsPath, { basePath } from "../../helpers/awsPaths";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const { logout } = authAction;
const { setRecipe } = varAction;

const api = new Api();
const FormItem = Form.Item;
class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
      passwordModal: false,
      logOutModal: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  handleLogout = () => {
    let url = "user/logout";
    api
      .post(url)
      .then((response) => {
        if (response.status === 1) {
          this.props.setRecipe({});
          this.props.logout();
          localStorage.clear();
        }
      })
      .catch((error) => {
        return error;
      });
  };

  togglePasswordModal = () =>
    this.setState({ passwordModal: !this.state.passwordModal });

  toggleLogoutModal = () =>
    this.setState({ logOutModal: !this.state.logOutModal });

  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <a className="isoDropdownLink">
          <Link to="/companyDetails/companyData" style={{ color: "#595959" }}>
            Company Info
          </Link>
        </a>
        <a className="isoDropdownLink">
          <Link to="/editProfile" style={{ color: "#595959" }}>
            Edit Profile
          </Link>
        </a>
        <a className="isoDropdownLink" onClick={this.togglePasswordModal}>
          Change Password
        </a>
        <a
          className="isoDropdownLink"
          onClick={() => this.setState({ logOutModal: true })}
        >
          Logout
        </a>
      </TopbarDropdownWrapper>
    );
    return (
      <Fragment>
        <Popover
          content={content}
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
          // arrowPointAtCenter={true}
          placement="bottomLeft"
        >
          {/* <div className="isoImgWrapper">
            <img alt="user" src={userpic} />
            <span className="userActivity online" />
          </div> */}
          <div className="display-flex align-center gap-10px">
            <h2 className="f-size-18px" style={{ color: "#fff" }}>
              {this.props.auth.company.name}
            </h2>
            {this.props && this.props.auth && this.props.auth.profileImage && (
              <div className="profileImgWrapper">
                <img
                  alt="user"
                  style={{ borderRadius: "99px", width: "100%" }}
                  src={
                    this.props &&
                    this.props.auth &&
                    this.props.auth.profileImage
                      ? `${basePath}/${awsPath.originalImg}/${this.props.auth.profileImage}`
                      : userpic
                  }
                />
                <span className="userActivity online" />
              </div>
            )}
          </div>
        </Popover>
        <LogoutModal
          visible={this.state.logOutModal}
          toggleModal={this.toggleLogoutModal}
          handleLogout={this.handleLogout}
        />
        <PasswordModal
          visible={this.state.passwordModal}
          toggleModal={this.togglePasswordModal}
        />
      </Fragment>
    );
  }
}

const WrapPasswordModal = (props) => {
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { form } = props;
  const handleConfirmBlur = (e) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  const handleSubmit = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setIsLoading(true);
        api
          .post("user/changePassword", {
            isMultipart: false,
            data: {
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
            },
          })
          .then((response) => {
            if (response.status === 1) {
              message.success(response.message);
              props.form.resetFields();
              props.toggleModal();
              setIsLoading(false);
            } else {
              message.error(response.message);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            return error;
          });
      }
    });
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.toggleModal}
      title="Change password"
      okText="Reset"
      onOk={handleSubmit}
    >
      <Form {...formItemLayout} onSubmit={handleSubmit} labelAlign="left">
        <Form.Item label="Old password" hasFeedback>
          {form.getFieldDecorator("oldPassword", {
            rules: [
              {
                required: true,
                message: "Please old password!",
              },
              {
                validator: (_, value, callback) => {
                  if (!value || value.trim() === "") {
                    callback("Old password cannot be empty or just spaces!");
                  } else {
                    callback(); // Validation passed
                  }
                },
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item label="New Password" hasFeedback>
          {form.getFieldDecorator("newPassword", {
            rules: [
              {
                required: true,
                message: "Please input your password!",
              },
              {
                validator: (_, value, callback) => {
                  if (!value || value.trim() === "") {
                    callback("New password cannot be empty or just spaces!");
                  } else {
                    callback(); // Validation passed
                  }
                },
              },
              {
                validator: validateToNextPassword,
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item label="Confirm new password" hasFeedback>
          {form.getFieldDecorator("confirm", {
            rules: [
              {
                required: true,
                message: "Please confirm your password!",
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={handleConfirmBlur} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const LogoutModal = (props) => {
  return (
    <Modal
      visible={props.visible}
      onCancel={props.toggleModal}
      title="Logout"
      okText="Yes"
      onOk={props.handleLogout}
    >
      <h2>Are you sure you want to logout?</h2>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.Auth.company.id,
  auth: state.Auth,
});

const PasswordModal = Form.create()(WrapPasswordModal);

export default connect(mapStateToProps, { logout, setRecipe })(TopbarUser);
